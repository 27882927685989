.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: var(--primary-color);
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Arial", sans-serif;
  font-size: calc(2rem + 2vmin);
  color: var(--accent-color);
}

.title {
  font-weight: bold;
  font-size: calc(2rem + 2vmin);
}

.container {
  margin-top: 1rem;
}

.clients,
.portfolio {
  font-size: 1.5rem;
}
.services {
  font-size: 3rem;
}

.clients ul,
.portfolio ul,
.services ul {
  display: inline-block;
  padding: 0;
  margin: .5rem;
  text-align: center;
}
.clients ul li,
.portfolio ul li,
.services ul li {
  list-style-type: none;
}

.contact {
  margin: 1rem 0;
  font-size: 1.4rem;
}

@media screen and (max-width: 480px) {
  .clients,
  .portfolio,
  .services {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 480px) {
  .clients ul li,
  .portfolio ul li,
  .services ul li {
    display: inline;
  }

  .clients ul li:after,
  .portfolio ul li:after,
  .services ul li:after {
    content: " ";
    letter-spacing: 1rem;
    background: center center no-repeat url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAE9WlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgOS4xLWMwMDEgNzkuMTQ2Mjg5OTc3NywgMjAyMy8wNi8yNS0yMzo1NzoxNCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnBob3Rvc2hvcD0iaHR0cDovL25zLmFkb2JlLmNvbS9waG90b3Nob3AvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0RXZ0PSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VFdmVudCMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDI1LjEgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDI0LTEwLTA4VDE4OjIyOjQ0KzAxOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAyNC0xMC0wOFQxODoyODoxMyswMTowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAyNC0xMC0wOFQxODoyODoxMyswMTowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHBob3Rvc2hvcDpDb2xvck1vZGU9IjMiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTBmYWZmOGMtZDRmMy00ODU4LTgyZjItYmM3YzFhNTZkZGE5IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjUwZmFmZjhjLWQ0ZjMtNDg1OC04MmYyLWJjN2MxYTU2ZGRhOSIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOjUwZmFmZjhjLWQ0ZjMtNDg1OC04MmYyLWJjN2MxYTU2ZGRhOSI+IDx4bXBNTTpIaXN0b3J5PiA8cmRmOlNlcT4gPHJkZjpsaSBzdEV2dDphY3Rpb249ImNyZWF0ZWQiIHN0RXZ0Omluc3RhbmNlSUQ9InhtcC5paWQ6NTBmYWZmOGMtZDRmMy00ODU4LTgyZjItYmM3YzFhNTZkZGE5IiBzdEV2dDp3aGVuPSIyMDI0LTEwLTA4VDE4OjIyOjQ0KzAxOjAwIiBzdEV2dDpzb2Z0d2FyZUFnZW50PSJBZG9iZSBQaG90b3Nob3AgMjUuMSAoTWFjaW50b3NoKSIvPiA8L3JkZjpTZXE+IDwveG1wTU06SGlzdG9yeT4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5Z+PDFAAAAR0lEQVQImS3HsQ1AQBQA0OdEq0ShMAmzaWyjEQNJkIvmz6C54hWviogGE0bcuFLJhh0rhoQBC1rM6BJeHHhwIlcRUaMvPuQfOOIQWWBzlPcAAAAASUVORK5CYII=);
  }

  .contact {
    font-size: 2rem;
  }
}
@media screen and (max-height: 1028px) {
  .clients,
  .contact,
  .portfolio,
  .services {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 768px) {
  .clients,
  .portfolio,
  .services {
    font-size: 1.6rem;
  }
}
