:root {
  --primary-color: #111111;
  --accent-color: #EEEEEE;
}

a:link, a:visited {
  color: var(--accent-color);
  font-weight: bold;
}
a:hover {
  text-decoration: none;
}

body {
  background-color: var(--primary-color);
  margin: 0;
  font-family: 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
